<template>
    <v-container>
        <v-carousel
            hide-delimiter-background
            :height="carouselHeight"
            hide-delimiters
            class="pb-12"
            light
            cycle
        >
            <!--Code for the edition of carousel buttons-->
            <template v-slot:prev="{ on, attrs }">
                <v-btn class="hidden-sm-and-down" elevation="0" icon color="ideenBlue" x-large v-bind="attrs" v-on="on">
                    <v-icon>chevron_left</v-icon>
                </v-btn>
            </template>

            <template v-slot:next="{ on, attrs }">
                <v-btn class="hidden-sm-and-down" elevation="0" icon color="ideenBlue" x-large v-bind="attrs" v-on="on">
                    <v-icon>chevron_right</v-icon>
                </v-btn>
            </template>

            <!--Carousel item-->
            <v-carousel-item
                v-for="(item,i) in this.imgTitles"
                :key="i"
                transition="fade-transition"
            >
                <v-container>
                    <v-row align="center" justify="center">

                    </v-row>

                    <v-row
                        align="center"
                        justify="center"
                    >
                        <v-col cols="12" md="10">
                            <v-img
                                class="rounded carousel"
                                :src="require(`../../assets/portfolio/${item}`)"
                            ></v-img>
                        </v-col>
                    </v-row>
                </v-container>
            </v-carousel-item>

        </v-carousel>
    </v-container>
</template>

<script>
export default {
    name: "Portfolio_Carousel",
    props: {
        imgHeight: {
            type: Number,
            default: 400
        },
        imgTitles: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            carouselHeight: "90vh"
        }
    },
    mounted() {
        let breakpointName = this.$vuetify.breakpoint.name

        if(breakpointName === 'sm' || breakpointName === 'xs') {
            this.carouselHeight = "30vh"
        }
    }
}
</script>

<style scoped>
.v-btn {
    background-color: white;
}

.carousel {
    box-shadow: 1px 1px 2px 2px grey;
}

@media (max-width: 760px) {
    .chevrons {
        margin-top: -500px;
    }
}
</style>