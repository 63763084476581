<template>
    <v-container>
        <!--DESKTOP PORTFOLIO CONTENT-->
        <v-container class="hidden-sm-and-down">
            <!--Title-->
            <v-row justify="center" align="center" class="mt-16 mb-10">
                <p class="entryTitle">
                    {{ items[selected]['title'] }}
                </p>
            </v-row>

            <v-row no-gutters justify="center">
                <v-col xl="11" lg="11" md="11" sm="11">
                    <v-row no-gutters>
                        <v-col cols="8">
                            <v-col cols="11">
                                <v-row class="mt-4 mb-2">
                                    <p class="boldText">
                                        {{ titles[0]['need'] }} <!-- 0 -->
                                    </p>
                                </v-row>
                                <v-row>
                                    <p class="normalText">
                                        {{ items[selected]['needAnswer'] }}
                                    </p>
                                </v-row>
                                <v-row class="mt-16 mb-2">
                                    <p class="boldText">
                                        {{ titles[0]['solution'] }} <!-- 0 -->
                                    </p>
                                </v-row>
                                <v-row>
                                    <p class="normalText">
                                        {{ items[selected]['solutionAnswer'] }}
                                    </p>
                                </v-row>
                            </v-col>
                        </v-col>

                        <v-col cols="4">
                            <v-col cols="12" class="pl-16 pr-16">
                                <!--Client logo image-->
                                <v-row class="pa-6">
                                    <v-img
                                        :src="require(`../../assets/portfolio/${items[selected]['imgLogo']}`)"
                                        class="roundedImage"
                                        aspect-ratio="1"
                                    ></v-img>
                                </v-row>

                                <!--Client webpage link-->
                                <v-row justify="center">
                                    <a :href="'https://' + items[selected]['link']" target="_blank" class="productURL">
                                        <p class="ml-1 mr-1">
                                            {{ items[selected]['link'] }}
                                        </p>
                                    </a>
                                </v-row>

                                <v-row>
                                    <p class="normalText">
                                        {{ items[selected]['description'] }}
                                    </p>
                                </v-row>
                                <v-row class="mt-4">
                                    <p class="boldText">
                                        {{ titles[0]['field'] }} <!-- 0 -->
                                    </p>
                                </v-row>
                                <v-row class="mt-0">
                                    <p class="normalText">
                                        {{ items[selected]['fieldAnswer'] }}
                                    </p>
                                </v-row>
                                <v-row class="mt-4">
                                    <p class="boldText">
                                        {{ titles[0]['service'] }} <!-- 0 -->
                                    </p>
                                </v-row>
                                <v-row class="mt-0">
                                    <p class="normalText">
                                        {{ items[selected]['serviceAnswer'] }}
                                    </p>
                                </v-row>
                            </v-col>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>

        <!-- MOBILE PORTFOLIO CONTENT -->
        <v-container class="hidden-md-and-up">
            <v-row no-gutters>
                <v-col cols="12">
                    <!-- TITLE -->
                    <v-row class="mt-4 mb-n8" justify="center" align="center">
                        <p class="entryTitle">
                            {{ items[selected]['title'] }}
                        </p>
                    </v-row>

                    <!--Client logo image-->
                    <v-row class="ml-4 mr-4">
                        <v-img
                            :src="require(`../../assets/portfolio/${items[selected]['imgLogo']}`)"
                            class="roundedImage"
                            aspect-ratio="1"
                        ></v-img>
                    </v-row>

                    <!-- Product profile info -->
                    <v-row class="mt-8 mb-6" justify="center">
                        <a :href="'https://' + items[selected]['link']" target="_blank" class="productURL">
                            <p class="ml-1 mr-1">
                                {{ items[selected]['link'] }}
                            </p>
                        </a>
                    </v-row>

                    <!-- PRODUCT DESCRIPTION -->
                    <v-row>
                        <p class="normalText ml-4 mr-4">
                            {{ items[selected]['description'] }}
                        </p>
                    </v-row>

                    <!-- CUSTOMER INDUSTRY -->
                    <v-row class="mt-4">
                        <p class="boldText ml-4 mr-4">
                            {{ titles[0]['field'] }}
                        </p>
                    </v-row>
                    <v-row class="mt-0">
                        <p class="normalText ml-4 mr-4">
                            {{ items[selected]['fieldAnswer'] }}
                        </p>
                    </v-row>


                    <!-- OFFERED SERVICE TO CUSTOMER -->
                    <v-row class="mt-4">
                        <p class="boldText ml-4 mr-4">
                            {{ titles[0]['service'] }}
                        </p>
                    </v-row>
                    <v-row class="mt-0">
                        <p class="normalText ml-4 mr-4">
                            {{ items[selected]['serviceAnswer'] }}
                        </p>
                    </v-row>

                    <!-- PRODUCT TYPE -->
                    <v-row>
                        <p class="boldText ml-4 mr-4">
                            {{ titles[0]['development'] }}
                        </p>
                    </v-row>
                    <v-row class="mt-0">
                        <p class="normalText ml-4 mr-4">
                            {{ items[selected]['developmentAnswer'] }}
                        </p>
                    </v-row>

                    <!-- CUSTOMER PROBLEM -->
                    <v-row class="mt-4 mb-3 ml-1">
                        <p class="boldText">
                            {{ titles[0]['need'] }}
                        </p>
                    </v-row>
                    <v-row>
                        <p class="normalText ml-4 mr-4">
                            {{ items[selected]['needAnswer'] }}
                        </p>
                    </v-row>

                    <!-- SOLUTION -->
                    <v-row class="mt-12 mb-3 ml-1">
                        <p class="boldText">
                            {{ titles[0]['solution'] }}
                        </p>
                    </v-row>
                    <v-row>
                        <p class="normalText ml-4 mr-4">
                            {{ items[selected]['solutionAnswer'] }}
                        </p>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>


        <!-- PRODUCT CAROUSEL -->
        <v-row class="mt-12 mb-16 mr-1 ml-1">
            <!--Carousel-->
            <PortfolioCarousel
                :imgTitles="imageTitles">
            </PortfolioCarousel>
        </v-row>
    </v-container>
</template>

<script>
import * as db from "../../constants/portfolio_database"
import PortfolioCarousel from '@/components/portfolio/Portfolio_Carousel'

export default {
    name: "PortfolioProduct_Screen",
    components: {
        PortfolioCarousel
    },
    data() {
        return {
            selected: 0,
            items: null,
            titles: null,
            imageTitles: null
        }
    },
    computed: {
        //Gets current language
        language() {
            return this.$store.state.language
        }
    },
    beforeMount() {
        //receive id generated from "Portfolio_List"
        this.selected = this.$route.params.id
        // Import services info
        //Checks store value to import localized text
        if (this.language === 'es') {
            this.items = db.portfolio_info_es;
            this.titles = db.portfolio_titles_es;
            this.imageTitles = this.items[this.selected]['carouselImages'];

        } else {
            this.items = db.portfolio_info_en;
            this.titles = db.portfolio_titles_en;
            this.imageTitles = this.items[this.selected]['carouselImages'];
        }
    },
}
</script>

<style scoped>
.roundedImage {
    border-radius: 50%;
}

.boldText {
    font-family: "Montserrat", sans-serif;
    font-weight: bold;
    font-size: 1.5em;
    color: #464646;
}

.normalText {
    font-size: 1.15em;
    font-weight: lighter;
    color: #464646;
    font-family: Montserrat, sans-serif;
}

.productURL {
    text-decoration: none !important;
    font-weight: bold;
    font-size: 1.5em;
    color: #464646;
}

.productURL:hover {
    color: #0131B7 !important;
}

.entryTitle {
    font-family: "Montserrat", sans-serif;
    font-size: xxx-large;
    font-weight: bold;
    color: #464646;
}

@media (max-width: 760px) {
    .normalText {
        font-size: 1em;
        font-weight: lighter;
        color: #464646;
        font-family: Montserrat, sans-serif;
    }

    .boldText {
        font-weight: bold;
        font-size: 1.25em;
        color: #464646;
    }
}
</style>